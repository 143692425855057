<template>
  <div class="relative z-40">
    <div
      class="flex flex-col items-center justify-center gap-1 border-b border-info-300 bg-info-50 px-4 py-2 text-sm text-gray-500 md:h-10 md:flex-row md:px-0"
    >
      <!-- Small screen -->
      <p class="space-x-1 text-center md:hidden">
        <span class="inline-block">
          <div
            class="flex size-6 items-center justify-center rounded-full bg-white"
          >
            <BaseIcon name="megaphone" filled class="inline-block" />
          </div>
        </span>
        <span class="font-bold">{{ title }}</span>
        <span v-if="message">{{ message }}</span>
      </p>
      <!-- Wide screen -->
      <div class="hidden items-center justify-center gap-1 md:flex">
        <div
          class="flex size-6 items-center justify-center rounded-full bg-white"
        >
          <BaseIcon name="megaphone" filled />
        </div>
        <p class="font-bold">{{ title }}</p>
        <p v-if="message">{{ message }}</p>
      </div>
      <!-- Action -->
      <template v-if="action">
        <div
          class="my-1 w-1/2 border-t border-info-300 md:mx-2 md:h-full md:w-0 md:border-l"
        />
        <div
          class="flex cursor-pointer items-center font-medium"
          @click="action"
        >
          <p>{{ actionLabel ?? "Meer informatie" }}</p>
          <BaseIcon name="arrow-right" class="ml-1" />
        </div>
      </template>
      <!-- Close -->
      <BaseButton
        color="subtle"
        square
        small
        class="!absolute right-2 top-2 md:top-auto"
        @click="hide"
      >
        <BaseIcon name="close" class="text-xl" />
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import useNotificationStore from "@/stores/notificationStore.js"
import { BaseButton, BaseIcon } from "@repowerednl/ui-component-library"

const notificationStore = useNotificationStore()

const props = defineProps({
  uid: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    default: null,
  },
  action: {
    type: Function,
    default: null,
  },
  actionLabel: {
    type: String,
    default: null,
  },
})

function hide() {
  notificationStore.remove(props.uid)
}
</script>
