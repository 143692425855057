<template>
  <BaseButton
    class="w-full md:w-auto"
    color="primary"
    :disabled="!meteringPoint"
    :loading
    @click="downloadExport"
  >
    <BaseIcon name="download-2" filled class="mr-1" />
    Export
  </BaseButton>
</template>

<script setup>
import { ref } from "vue"
import { format } from "date-fns/format"
import base64Download from "@/composables/base64Download.js"
import useNotificationStore from "@/stores/notificationStore.js"
import { BaseIcon, BaseButton } from "@repowerednl/ui-component-library"

const props = defineProps({
  meteringPoint: {
    type: Object,
    default: null,
  },
  dateRange: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: null,
    validator: (value) => ["measurements", "results"].includes(value),
  },
})

const notificationStore = useNotificationStore()
const loading = ref(false)

const typeTitleMap = {
  measurements: "Meetgegevens",
  results: "Resultaten",
}

function onNewData(data) {
  base64Download(
    data.data,
    "xlsx",
    `${typeTitleMap[props.type]} ${props.meteringPoint.name} ` +
      `${format(props.dateRange[0], "yyyy-MM-dd")}_` +
      `${format(props.dateRange[1], "yyyy-MM-dd")}.xlsx`,
  )
  loading.value = false
}

function onLoadDataFailed(error) {
  notificationStore.pushError(
    "Fout bij het downloaden van export",
    `De gegevensexport kon niet worden gedownload. Probeer het later opnieuw. (code: ${error.code})`,
    "download-export-error",
  )
  loading.value = false
}

function downloadExport() {
  loading.value = true
  if (props.type === "measurements") {
    props.meteringPoint.loadMeasurements(
      null,
      ...props.dateRange,
      true,
      onNewData,
      onLoadDataFailed,
    )
  } else if (props.type === "results") {
    props.meteringPoint.loadRecordStreamCollection(
      null,
      ...props.dateRange,
      true,
      false,
      onNewData,
      onLoadDataFailed,
    )
  } else {
    console.error("Invalid export type")
    loading.value = false
  }
}
</script>
