export function formatNumber(value, precision) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "decimal",
    ...(precision !== null && {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }),
  })
  return formatter.format(value)
}

export function formatCurrency(value, currency, precision) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency ?? "EUR",
    minimumFractionDigits: precision ?? 2,
    maximumFractionDigits: precision ?? 2,
  })
  return formatter.format(value)
}

const prefixes = [
  { value: 1e15, symbol: "P" },
  { value: 1e12, symbol: "T" },
  { value: 1e9, symbol: "G" },
  { value: 1e6, symbol: "M" },
  { value: 1e3, symbol: "k" },
]

export function scaleWithUnit(value, unit) {
  let scale = 1
  let symbol = unit
  for (const prefix of prefixes) {
    if (Math.abs(value) >= prefix.value * 10) {
      scale /= prefix.value
      symbol = prefix.symbol + unit
      break
    }
  }
  return [scale, symbol]
}

export function formatUnit(value, unit, precision) {
  const [scale, symbol] = scaleWithUnit(value, unit)
  // Formatted with a 'non-breaking space' character U+00A0
  return `${formatNumber(value * scale, precision)}\u00A0${symbol}`
}
