import { defineStore } from "pinia"
import { fetchApiJson } from "@/services/repowerAPIService.js"
import useSingleAssetStoreFactory from "@/stores/repower/singleAssetStoreFactory.js"

export default defineStore("assetsStore", {
  state: () => ({
    loading: false,
    loadingFailed: false,
    assets: [],
  }),
  actions: {
    loadAssets(onSuccess, onFail) {
      this.loading = true
      fetchApiJson("/v2/assets/")
        .then((data) => {
          this.assets = []
          for (const asset of data) {
            const singleAssetStore = useSingleAssetStoreFactory(asset.id)
            singleAssetStore.setAsset(asset)
            this.assets.push(singleAssetStore)
          }
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          this.loadingFailed = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
