<template>
  <div class="flex flex-col gap-4">
    <TextHeader>Dashboard</TextHeader>
    <TextTitle
      class="w-fit cursor-pointer hover:text-primary active:text-primary-600"
      @click="onAssetsTitleClicked"
      >Realtime vermogen</TextTitle
    >
    <div class="grid gap-4 xl:grid-cols-2 min-[2048px]:grid-cols-3">
      <AssetChartPanel
        v-for="asset in assetsShown"
        :key="asset.id"
        :metering-point="asset"
        :date-range="[startOfToday(), startOfToday()]"
        show-title
      />
    </div>
    <BaseButton
      v-if="hiddenAssetsCount > 0"
      color="secondary"
      class="mx-auto w-fit"
      @click="onShowMore"
    >
      <BaseIcon name="arrow-down-double" class="mr-2" />
      Show {{ Math.min(4, hiddenAssetsCount) }} more asset{{
        hiddenAssetsCount > 1 ? "s" : ""
      }}
    </BaseButton>
    <BaseDivider />
    <div>
      <TextTitle
        class="w-fit cursor-pointer hover:text-primary active:text-primary-600"
        @click="onResultsTitleClicked"
      >
        Resultaat {{ getYear(startOfToday()) }}
      </TextTitle>
      <TextDescription> De resultaten over het lopende jaar. </TextDescription>
    </div>
    <ResultsChartPanel
      :metering-point="firstPortfolio || assetsStore.assets[0]"
      :card-title="firstPortfolio?.name || assetsStore.assets[0]?.name"
      :date-range="[startOfYear(startOfToday()), lastDayOfYear(startOfToday())]"
      mode="both"
    />
    <BaseDivider />
    <TextTitle
      class="w-fit cursor-pointer hover:text-primary active:text-primary-600"
      @click="onMarketsTitleClicked"
      >Marktinzichten</TextTitle
    >
    <div class="grid grid-cols-1 gap-2 md:gap-4 xl:grid-cols-2">
      <DayAheadChartPanel :date-range="[startOfToday(), startOfToday()]" />
      <BalanceDeltaChartPanel :date-range="[startOfToday(), startOfToday()]" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { startOfToday, getYear, startOfYear, lastDayOfYear } from "date-fns"
import useDashboardStore from "@/stores/dashboardStore.js"
import useAssetsStore from "@/stores/repower/assetsStore.js"
import usePortfoliosStore from "@/stores/repower/portfoliosStore.js"
import useToolbarStore from "@/stores/toolbarStore.js"
import {
  TextHeader,
  TextTitle,
  TextDescription,
  BaseDivider,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"
import AssetChartPanel from "@/components/panels/AssetChartPanel.vue"
import ResultsChartPanel from "@/components/panels/ResultsChartPanel.vue"
import DayAheadChartPanel from "@/components/panels/DayAheadChartPanel.vue"
import BalanceDeltaChartPanel from "@/components/panels/BalanceDeltaChartPanel.vue"

const router = useRouter()
const dashboardStore = useDashboardStore()
const assetsStore = useAssetsStore()
const portfoliosStore = usePortfoliosStore()
const toolbarStore = useToolbarStore()

const assetsNoConsumers = computed(() => {
  return assetsStore.assets.filter((asset) => asset.type !== "Consumer")
})

const assetsShown = computed(() => {
  return assetsNoConsumers.value.slice(0, dashboardStore.limit)
})

const hiddenAssetsCount = computed(() => {
  return assetsNoConsumers.value.length - dashboardStore.limit
})

const firstPortfolio = computed(() => {
  return portfoliosStore.portfolios[0]
})

function onShowMore() {
  dashboardStore.limit = Math.min(
    dashboardStore.limit + 4,
    assetsNoConsumers.value.length,
  )
}

function onAssetsTitleClicked() {
  toolbarStore.selection = assetsStore.assets[0]
  toolbarStore.setDateRange([startOfToday(), startOfToday()])
  router.push({ name: "assets" })
}

function onResultsTitleClicked() {
  if (firstPortfolio.value) {
    toolbarStore.selection = firstPortfolio.value
  }
  toolbarStore.setDateRange([
    startOfYear(startOfToday()),
    lastDayOfYear(startOfToday()),
  ])
  toolbarStore.setMode("both")
  router.push({ name: "results" })
}

function onMarketsTitleClicked() {
  router.push({ name: "markets" })
}
</script>
