import { format, addDays } from "date-fns"
import useSessionStore from "@/stores/sessionStore"

const BASE_URL = `${import.meta.env.VITE_API_URL}/api`

export function fetchApi(path, options) {
  const url = `${BASE_URL}${path}`
  return fetch(url, options)
}

export function fetchApiCatch(path, options, toJSON = false) {
  return fetchApi(path, options)
    .then((response) => {
      if (response.status === 200) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to fetch from API: '${path}' (code: ${error.code})`)
      throw error
    })
}

export function fetchApiJson(path, options) {
  return fetchApiCatch(path, options, true)
}

export function postApi(path, body, options) {
  const sessionStore = useSessionStore()
  return fetchApi(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": sessionStore.csrfToken,
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  })
}

export function postApiCatch(path, body, options, toJSON = false) {
  return postApi(path, body, options)
    .then((response) => {
      if (response.status == 200) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to post to API: '${path}' (code: ${error.code})`)
      throw error
    })
}

export function postApiJson(path, body, options) {
  return postApiCatch(path, body, options, true)
}

export function makeDataFetchObject() {
  return {
    params: null,
    data: null,
  }
}

export function prepareDataFetch(
  cache,
  freq,
  dateStart,
  dateEnd,
  isFinal,
  onlyTotals,
  onSuccess,
) {
  dateEnd = addDays(dateEnd, 1) // Include last day
  const paramsObj = new URLSearchParams({
    freq,
    date_start: format(dateStart, "yyyy-MM-dd"),
    date_end: format(dateEnd, "yyyy-MM-dd"),
    is_final: isFinal,
    only_totals: onlyTotals,
  })
  paramsObj.delete("freq", null)
  paramsObj.delete("is_final", null)
  paramsObj.delete("only_totals", null)
  const params = paramsObj.toString()
  if (cache && cache.data && cache.params === params) {
    if (onSuccess) {
      onSuccess(cache.data)
    }
    return
  }
  return params
}

export function handleDataFetchPromise(
  cache,
  promise,
  params,
  onSuccess,
  onFail,
) {
  if (cache) cache.data = null
  promise
    .then((data) => {
      if (cache) {
        cache.data = data
        cache.params = params
      }
      if (onSuccess) {
        onSuccess(data)
      }
    })
    .catch((error) => {
      if (onFail) {
        onFail(error)
      }
    })
}
