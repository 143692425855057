<template>
  <BaseCard class="flex h-[500px] flex-col gap-4 p-4 md:p-6">
    <!-- Header -->
    <div>
      <TextSubtitle>Voorlopige onbalans</TextSubtitle>
      <TextDescription>Huidige prijs: €{{ currentPrice }}</TextDescription>
    </div>
    <!-- Stat row -->
    <LoadingWrapper :error="loadingFailed" :loading="loading">
      <div class="flex items-start justify-between">
        <!-- Stat average -->
        <StatComponent
          label="Gemiddelde prijs"
          :value="avgPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-up-down" class="fill-finance" />
          </template>
        </StatComponent>
        <!-- Stat minimum -->
        <StatComponent
          label="Laagste prijs"
          :value="minPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-down" class="fill-danger" />
          </template>
        </StatComponent>
        <!-- Stat maximum -->
        <StatComponent
          label="Hoogste prijs"
          :value="maxPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-up" class="fill-success" />
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <TimeseriesChart
        chart-type="line"
        :series="series"
        :axes="axes"
        :data="chartData"
        :x-limits="[dateRange[0], endOfDay(dateRange[1])]"
      />
    </LoadingWrapper>
  </BaseCard>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import { endOfDay } from "date-fns"
import { formatNumber } from "@/services/formatterService.js"
import { colors } from "@/services/themeService.js"
import useMarketPricesStore from "@/stores/repower/marketPricesStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseCard,
  BaseIcon,
  TextSubtitle,
  TextDescription,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import StatComponent from "@/components/information/StatComponent.vue"

const props = defineProps({
  dateRange: {
    type: Array,
    required: true,
  },
})

const marketPricesStore = useMarketPricesStore()
const notificationStore = useNotificationStore()
const chartData = ref({})
const loading = ref(true)
const loadingFailed = ref(false)

const series = {
  min_price: {
    label: "Min. prijs (per MWh)",
    axis: "finance",
    colorScale: colors.danger,
  },
  max_price: {
    label: "Max. prijs (per MWh)",
    axis: "finance",
    colorScale: colors.success,
  },
  mid_price: {
    label: "Mid. prijs (per MWh)",
    axis: "finance",
    colorScale: colors.finance,
  },
}

const axes = {
  finance: {
    format: "€ _",
    precision: 0,
  },
}

const currentPrice = computed(() => {
  const { data } = marketPricesStore.balanceDeltaPrices
  if (!data) return "-"
  const { min_price, mid_price, max_price } = Object.values(data).slice(-1)[0]
  return formatNumber(min_price ?? max_price ?? mid_price, 2)
})

const avgPrice = computed(() => {
  const { data } = marketPricesStore.balanceDeltaPrices
  if (!data) return NaN
  const prices = Object.values(data)
    .map((value) => value.mid_price)
    .filter((price) => price !== null)
  return prices.reduce((total, price) => total + price, 0) / prices.length
})

const minPrice = computed(() => {
  const { data } = marketPricesStore.balanceDeltaPrices
  if (!data) return NaN
  const prices = Object.values(data)
    .map((value) => value.min_price)
    .filter((price) => price !== null)
  return Math.min(...prices)
})

const maxPrice = computed(() => {
  const { data } = marketPricesStore.balanceDeltaPrices
  if (!data) return NaN
  const prices = Object.values(data)
    .map((value) => value.max_price)
    .filter((price) => price !== null)
  return Math.max(...prices)
})

function onNewPrices(data) {
  chartData.value = data
  loading.value = false
}

function onLoadPricesFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van onbalans prijzen",
    `De onbalans prijzen konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-imbalance-market-error",
  )
}

onMounted(() => {
  marketPricesStore.loadBalanceDeltaPrices(
    ...props.dateRange,
    onNewPrices,
    onLoadPricesFailed,
  )
})
</script>
