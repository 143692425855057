<template>
  <div class="flex flex-col gap-4">
    <!-- Chart panel -->
    <AssetExpectedRevenuePanel
      :metering-point="toolbarStore.selection"
      :date-range="toolbarStore.dateRange"
      show-stats
    />
  </div>
</template>

<script setup>
import { startOfToday } from "date-fns"
import useToolbarStore from "@/stores/toolbarStore.js"
import AssetExpectedRevenuePanel from "@/components/panels/AssetExpectedRevenuePanel.vue"

const toolbarStore = useToolbarStore()

if (!toolbarStore.selection?.isAsset) {
  toolbarStore.selection = null
  toolbarStore.dateRange = [startOfToday(), startOfToday()]
}
</script>
