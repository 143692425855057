<template>
  <div
    class="mx-auto flex max-w-[480px] flex-col items-center gap-4 md:mt-6 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <TextTitle>Wachtwoord instellen</TextTitle>
      <!-- Error messages -->
      <MessageComponent
        v-if="tokenInvalid || cannotValidateToken"
        color="danger"
      >
        <p v-if="tokenInvalid">
          De link is ongeldig of verlopen. Vraag een nieuwe link aan.
        </p>
        <p v-else-if="cannotValidateToken">
          Er ging iets mis bij de validatie van de link. Probeer het later
          opnieuw of vraag een nieuwe link aan.
        </p>
      </MessageComponent>
      <!-- Password instructions -->
      <p>
        Kies een wachtwoord dat je nergens anders gebruikt met de volgende
        eigenschappen:
      </p>
      <ul class="list-inside list-disc">
        <li>Bevat minimaal 8 tekens</li>
        <li>Mag niet enkel uit cijfers bestaan</li>
        <li>Geen veelvoorkomend wachtwoord</li>
        <li>Mag niet op het e-mailadres lijken</li>
      </ul>
      <!-- Password change form -->
      <form
        autocomplete="off"
        class="flex w-full flex-col gap-4"
        @submit.prevent
      >
        <InputField
          v-model="inputNewPassword"
          type="password"
          label="Nieuw wachtwoord"
          :disabled="tokenInvalid || cannotValidateToken || isLoading"
          :state-error="passwordIllegal"
          :helper-text="
            passwordIllegal ? 'Wachtwoord voldoet niet aan de eisen' : null
          "
        />
        <BaseButton
          color="primary"
          class="w-fit"
          :disabled="tokenInvalid || cannotValidateToken"
          :loading="isLoading"
          @click="onSend"
        >
          Wijzig wachtwoord
        </BaseButton>
      </form>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { fetchApiCatch, postApiCatch } from "@/services/repowerAPIService"
import useNotificationStore from "@/stores/notificationStore"
import {
  TextTitle,
  BaseCard,
  BaseButton,
  InputField,
} from "@repowerednl/ui-component-library"
import MessageComponent from "@/components/information/MessageComponent.vue"

const notificationStore = useNotificationStore()
const route = useRoute()
const router = useRouter()
const inputNewPassword = ref("")
const tokenInvalid = ref(false)
const cannotValidateToken = ref(false)
const isLoading = ref(false)
const passwordIllegal = ref(false)

function validateToken(token) {
  fetchApiCatch(`/users/validate-password-reset-token/?token=${token}`).catch(
    (error) => {
      if (error.code === 403) {
        tokenInvalid.value = true
      } else {
        cannotValidateToken.value = true
      }
    },
  )
}

function onSend() {
  if (tokenInvalid.value || cannotValidateToken.value) return
  isLoading.value = true
  postApiCatch(
    "/users/reset-password/",
    {
      token: route.query.token,
      new_password: inputNewPassword.value,
    },
    { method: "PUT" },
  )
    .then(() => {
      router.replace({ name: "login" })
      notificationStore.pushToast(
        "Wachtwoord gewijzigd.",
        "Je kunt nu inloggen met je nieuwe wachtwoord.",
      )
    })
    .catch((error) => {
      if (error.code === 400) {
        passwordIllegal.value = true
      } else if (error.code === 403) {
        tokenInvalid.value = true
      } else {
        tokenInvalid.value = true
        notificationStore.pushError(
          "Wachtwoord wijzigen niet mogelijk",
          `Er ging iets mis bij het wijzigen van het wachtwoord. Probeer het later opnieuw. (code: ${error.code})`,
        )
      }
    })
    .finally(() => {
      isLoading.value = false
    })
}

watch(inputNewPassword, () => {
  passwordIllegal.value = false
})

onMounted(() => {
  const token = route.query.token
  validateToken(token)
})
</script>
