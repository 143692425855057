<template>
  <div
    ref="anchorRef"
    class="w-fit"
    v-bind="$attrs"
    @mouseover="show = true"
    @mouseleave="show = false"
  >
    <slot>
      <BaseIcon
        name="information"
        filled
        class="cursor-help text-gray-300 hover:text-gray-700"
      />
    </slot>
  </div>
  <div
    v-if="show"
    ref="tooltipRef"
    class="absolute left-0 top-0 z-50 w-[150px] whitespace-normal rounded-lg bg-gray-800 p-2 text-center text-xs font-normal text-white"
    :style="floatingStyles"
  >
    <slot name="content">{{ description }}</slot>
    <div
      ref="arrowRef"
      class="absolute size-2 rotate-45 bg-gray-800"
      :class="placement === 'top' ? '-bottom-1' : '-top-1'"
      :style="{
        left: middlewareData.arrow?.x + 'px',
      }"
    />
  </div>
</template>

<script setup>
import { ref } from "vue"
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
  arrow,
} from "@floating-ui/vue"
import { BaseIcon } from "@repowerednl/ui-component-library"

defineProps({
  description: {
    type: String,
    default: null,
  },
})

const show = ref(false)
const anchorRef = ref()
const tooltipRef = ref()
const arrowRef = ref()
const { floatingStyles, middlewareData, placement } = useFloating(
  anchorRef,
  tooltipRef,
  {
    whileElementsMounted: autoUpdate,
    placement: "top",
    middleware: [
      offset(6),
      flip({ padding: 50 }),
      shift({ padding: 12 }),
      arrow({ element: arrowRef }),
    ],
  },
)
</script>
