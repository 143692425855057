<template>
  <BaseCard class="flex flex-col gap-2 p-4 md:p-6">
    <div v-if="meteringPoint">
      <BaseButton
        v-if="showTitle"
        narrow
        color="subtle"
        class="-mx-2"
        @click="onTitleClicked"
      >
        <StatusIndicatorComponent :asset="meteringPoint" class="size-6" />
        <TextSubtitle class="mx-2">{{ meteringPoint.name }}</TextSubtitle>
        <BaseBadge class="!bg-energy-50 !text-energy-900">
          <BaseIcon :name="meteringPoint.icon" filled />
        </BaseBadge>
      </BaseButton>
      <slot />
    </div>
    <div
      v-else
      class="flex h-full items-center justify-center p-4 italic md:p-6"
    >
      <p>Selecteer een asset of portfolio.</p>
    </div>
  </BaseCard>
</template>

<script setup>
import { useRouter } from "vue-router"
import { startOfToday } from "date-fns"
import useToolbarStore from "@/stores/toolbarStore.js"
import {
  BaseCard,
  BaseIcon,
  BaseBadge,
  BaseButton,
  TextSubtitle,
} from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const props = defineProps({
  meteringPoint: {
    type: Object,
    default: null,
  },
  showTitle: Boolean,
})

const router = useRouter()
const toolbarStore = useToolbarStore()

function onTitleClicked() {
  if (props.meteringPoint) {
    toolbarStore.selection = props.meteringPoint
    toolbarStore.setDateRange([startOfToday(), startOfToday()])
    router.push({ name: "assets-analysis" })
  }
}
</script>
