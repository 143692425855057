<template>
  <BaseBadge :color="badgeColor">
    <BaseIcon :name="iconName" />
    {{ displayValue }}%
  </BaseBadge>
</template>

<script setup>
import { computed } from "vue"
import { BaseBadge, BaseIcon } from "@repowerednl/ui-component-library"

const props = defineProps({
  deltaValue: {
    type: Number,
    required: true,
  },
})

const badgeColor = computed(() => {
  if (props.deltaValue > 0) {
    return "success"
  } else if (props.deltaValue < 0) {
    return "danger"
  } else {
    return "gray"
  }
})

const iconName = computed(() => {
  if (props.deltaValue > 0) {
    return "arrow-right-up"
  } else if (props.deltaValue < 0) {
    return "arrow-right-down"
  } else {
    return "equal"
  }
})

const displayValue = computed(() => {
  return Math.abs(Math.round(props.deltaValue * 100, 0))
})
</script>
