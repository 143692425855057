<template>
  <div
    class="mx-auto flex max-w-[480px] flex-col items-center gap-4 md:mt-6 md:w-[480px]"
  >
    <!-- Login -->
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <TextTitle>Inloggen</TextTitle>
      <!-- Incorrect credentials -->
      <MessageComponent v-if="showCredentialsError" color="danger">
        <p>De combinatie van e-mailadres en wachtwoord is niet geldig.</p>
      </MessageComponent>
      <!-- Form -->
      <form action="" class="flex w-full flex-col gap-4" @submit.prevent>
        <InputField
          v-model="inputEmail"
          type="text"
          label="E-mailadres"
          :disabled="isLoading"
          :state-error="inputError"
        />
        <InputField
          v-model="inputPassword"
          type="password"
          :disabled="isLoading"
          label="Wachtwoord"
          :state-error="inputError"
        />
        <div class="flex flex-wrap justify-between gap-4">
          <BaseButton color="primary" :loading="isLoading" @click="login"
            >Inloggen
          </BaseButton>
          <RouterLink :to="{ name: 'forgot-password' }">
            <BaseButton color="subtle" class="text-primary">
              Wachtwoord vergeten
            </BaseButton>
          </RouterLink>
        </div>
      </form>
    </BaseCard>
    <!-- Support -->
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <TextSubtitle>Nieuw account of vragen?</TextSubtitle>
      <p>
        Wil je een nieuw account aanmaken of heb je andere vragen, neem dan
        contact op met Repowered.
      </p>
      <a href="mailto:support@repowered.nl">
        <BaseButton color="secondary" class="w-fit">
          Contact opnemen
        </BaseButton>
      </a>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRouter, RouterLink } from "vue-router"
import useNotificationStore from "@/stores/notificationStore.js"
import useUserStore from "@/stores/userStore.js"
import {
  TextTitle,
  TextSubtitle,
  BaseCard,
  BaseButton,
  InputField,
} from "@repowerednl/ui-component-library"
import MessageComponent from "@/components/information/MessageComponent.vue"

const notificationStore = useNotificationStore()
const userStore = useUserStore()
const router = useRouter()
const inputEmail = ref("")
const inputPassword = ref("")
const isLoading = ref(false)
const inputError = ref(false)
const showCredentialsError = ref(false)

function onLoginSuccess() {
  notificationStore.pushToast("Je bent ingelogd.")
  router.push({ name: "dashboard" })
}

function onLoginUnknown() {
  inputError.value = true
  showCredentialsError.value = true
  isLoading.value = false
}

function onLoginFail(error) {
  notificationStore.pushError(
    "Inloggen niet mogelijk",
    `Er ging iets mis bij het inloggen. Vernieuw de pagina en probeer het opnieuw. (code: ${error.code})`,
  )
  isLoading.value = false
}

function login() {
  isLoading.value = true
  userStore.login(
    inputEmail.value,
    inputPassword.value,
    onLoginSuccess,
    onLoginUnknown,
    onLoginFail,
  )
}

watch([inputEmail, inputPassword], () => {
  inputError.value = false
})
</script>
