<template>
  <div
    class="mx-auto flex max-w-[480px] flex-col items-center gap-4 md:mt-6 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <TextTitle>E-mailadres wijzigen</TextTitle>
      <!-- Error messages -->
      <MessageComponent v-if="disableForm" color="danger">
        <p v-if="tokenInvalid">
          De link is ongeldig of verlopen. Vraag een nieuwe link aan.
        </p>
        <p v-else-if="emailUnavailable">
          Het opgegeven e-mailadres kan niet worden gebruikt voor dit account.
          Vraag een nieuwe link aan met een ander e-mailadres.
        </p>
        <p v-else>
          Er ging iets mis bij de validatie van de link. Probeer het later
          opnieuw of vraag een nieuwe link aan.
        </p>
      </MessageComponent>
      <!-- Email change form -->
      <LoadingWrapper :loading="isLoading">
        <div class="flex flex-col gap-2">
          <p>
            Je staat op het punt om je e-mailadres te wijzigen{{
              tokenInvalid || cannotValidateToken ? "." : " naar:"
            }}
          </p>
          <p class="font-bold">{{ newEmail }}</p>
          <p>
            Dit wordt het e-mailadres waarmee je voortaan inlogt. Vul je
            wachtwoord in en klik op 'Wijzig e-mailadres' om dit te bevestigen.
          </p>
        </div>
        <!-- Password change form -->
        <form
          autocomplete="off"
          class="flex w-full flex-col gap-4"
          @submit.prevent
        >
          <InputField
            v-model="inputPassword"
            type="password"
            label="Wachtwoord"
            :disabled="disableForm"
            :state-error="passwordIncorrect"
            :helper-text="passwordIncorrect ? 'Wachtwoord is onjuist' : null"
          />
          <BaseButton
            color="primary"
            class="w-fit"
            :disabled="disableForm"
            :loading="isLoading"
            @click="onSend"
          >
            Wijzig e-mailadres
          </BaseButton>
        </form>
      </LoadingWrapper>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { fetchApiJson } from "@/services/repowerAPIService"
import useNotificationStore from "@/stores/notificationStore"
import useUserStore from "@/stores/userStore"
import {
  TextTitle,
  BaseCard,
  BaseButton,
  InputField,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import MessageComponent from "@/components/information/MessageComponent.vue"

const notificationStore = useNotificationStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const isLoading = ref(true)
const newEmail = ref()
const inputPassword = ref("")

const tokenInvalid = ref(false)
const cannotValidateToken = ref(false)
const emailUnavailable = ref(false)
const passwordIncorrect = ref(false)

const disableForm = computed(
  () =>
    tokenInvalid.value || cannotValidateToken.value || emailUnavailable.value,
)

function validateEmailChangeToken(token) {
  fetchApiJson(`/users/validate-email-change-token/?token=${token}`)
    .then(({ new_email }) => {
      newEmail.value = new_email
    })
    .catch((error) => {
      if (error.code === 403) {
        tokenInvalid.value = true
      } else {
        cannotValidateToken.value = true
      }
    })
    .finally(() => {
      isLoading.value = false
    })
}

function onSend() {
  isLoading.value = true
  userStore.changeEmail(
    newEmail.value,
    route.query.token,
    inputPassword.value,
    () => {
      isLoading.value = false
      router.replace({ name: "dashboard" })
      notificationStore.pushToast(
        "E-mailadres gewijzigd",
        `Je e-mailadres is gewijzigd naar ${newEmail.value}`,
      )
    },
    (error) => {
      isLoading.value = false
      if (error.code === 401) passwordIncorrect.value = true
      else if (error.code === 403) tokenInvalid.value = true
      else if (error.code === 409) emailUnavailable.value = true
      else cannotValidateToken.value = true
    },
  )
}

watch(inputPassword, () => {
  passwordIncorrect.value = false
})

onMounted(() => {
  validateEmailChangeToken(route.query.token)
})
</script>
