<template>
  <div class="flex flex-col items-center">
    <!-- Icon -->
    <span :class="iconClass">
      <slot name="icon" />
    </span>
    <!-- Value -->
    <div class="flex items-center space-x-1">
      <div :class="valueClass" class="text-center font-bold">
        {{ displayValue }}
      </div>
      <DeltaBadgeComponent v-if="delta !== false" :delta-value="delta" />
    </div>
    <!-- Label -->
    <TextDescription class="text-center" :class="labelClass">
      <slot name="description" :label="label">
        {{ label }}
      </slot>
    </TextDescription>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { formatNumber, formatUnit } from "@/services/formatterService.js"
import { TextDescription } from "@repowerednl/ui-component-library"
import DeltaBadgeComponent from "@/components/information/DeltaBadgeComponent.vue"

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: Number,
    default: NaN,
  },
  delta: {
    type: [Number, Boolean],
    default: false,
  },
  unit: {
    type: [String, Boolean],
    default: false,
  },
  format: {
    type: String,
    default: "",
  },
  precision: {
    type: Number,
    default: 0,
  },
  small: {
    type: Boolean,
    default: false,
  },
})

const displayValue = computed(() => {
  if (isNaN(props.value)) {
    return "-"
  }
  let valueString
  if (props.unit !== false) {
    valueString = formatUnit(props.value, props.unit, props.precision)
  } else {
    valueString = formatNumber(props.value, props.precision)
  }
  return props.format ? props.format.replace("_", valueString) : valueString
})

const iconClass = computed(() => {
  return props.small ? "text-xl" : "text-2xl"
})

const valueClass = computed(() => {
  return props.small ? "text-base" : "text-sm md:text-xl"
})

const labelClass = computed(() => {
  return props.small ? "text-xs" : "text-base"
})
</script>
