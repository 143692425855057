<template>
  <div class="relative z-40">
    <div
      class="flex flex-col gap-1 rounded-lg border border-info-300 bg-info-50 p-4 text-gray-500"
    >
      <!-- Message -->
      <div class="flex items-center gap-2">
        <BaseIcon name="error-warning" filled class="text-lg" />
        <p class="font-bold">{{ title }}</p>
      </div>
      <p v-if="message" class="text-sm">{{ message }}</p>
      <!-- Action -->
      <BaseButton
        v-if="action"
        color="outline-dark"
        small
        class="mt-1 w-fit"
        @click="action"
      >
        {{ actionLabel ?? "Meer informatie" }}
      </BaseButton>
    </div>
    <!-- Close -->
    <BaseButton
      color="subtle"
      square
      small
      class="!absolute right-2 top-2"
      @click="hide"
    >
      <BaseIcon name="close" class="text-xl" />
    </BaseButton>
    <!-- Show count -->
    <div
      v-if="count > 1"
      class="absolute -left-1 -top-1 flex size-6 items-center justify-center rounded-full bg-gray text-sm text-white"
    >
      {{ count }}
    </div>
  </div>
</template>

<script setup>
import useNotificationStore from "@/stores/notificationStore.js"
import { BaseButton, BaseIcon } from "@repowerednl/ui-component-library"

const notificationStore = useNotificationStore()

const props = defineProps({
  uid: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    default: null,
  },
  action: {
    type: Function,
    default: null,
  },
  actionLabel: {
    type: String,
    default: null,
  },
  count: {
    type: Number,
    default: 1,
  },
})

function hide() {
  notificationStore.remove(props.uid)
}
</script>
