import { defineStore } from "pinia"
import { postApiJson, fetchApiCatch } from "@/services/repowerAPIService.js"
import useNotificationStore from "@/stores/notificationStore.js"
import useSessionStore from "@/stores/sessionStore.js"
import useChangelogStore from "@/stores/changelogStore.js"
import useAssetsStore from "@/stores/repower/assetsStore.js"
import usePortfoliosStore from "./repower/portfoliosStore"
import useToolbarStore from "@/stores/toolbarStore.js"
import useDashboardStore from "@/stores/dashboardStore.js"

export default defineStore("userStore", {
  state: () => ({
    fullName: "",
    email: "",
  }),
  actions: {
    setUser(fullName, email) {
      this.fullName = fullName
      this.email = email
    },
    loadMeteringPoints() {
      // Set up notifications
      const onLoadFail = (error) => {
        useNotificationStore().pushError(
          "Fout bij het laden van assets of portfolios",
          `Assets of portfolios konden niet worden opgehaald. Probeer het later
          opnieuw. (code: ${error.code})`,
          "load-metering-points-fail",
        )
      }
      // Load assets and portfolios
      useAssetsStore().loadAssets(null, onLoadFail)
      usePortfoliosStore().loadPortfolios(null, onLoadFail)
    },
    login(email, password, onSuccess, onUnknown, onFail) {
      // Attempt login
      postApiJson("/users/login/", { email, password })
        .then((data) => {
          this.setUser(data.user_full_name, data.user_email)
          useSessionStore().setAuthenticated(true)
          useChangelogStore().loadDateUpdated()
          this.loadMeteringPoints()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (error.code === 400) {
            if (onUnknown) onUnknown(error)
          } else {
            if (onFail) onFail(error)
          }
        })
    },
    logout(onSuccess, onFail) {
      fetchApiCatch("/users/logout/")
        .then(() => {
          // Reset session
          const sessionStore = useSessionStore()
          sessionStore.setAuthenticated(false)
          sessionStore.requestCsrfToken()
          // Reset other stores
          useChangelogStore().$reset()
          useAssetsStore().$reset()
          usePortfoliosStore().$reset()
          useToolbarStore().$reset()
          useDashboardStore().$reset()
          this.$reset()
          // Run callback
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    changeFullName(fullName, onSuccess, onFail) {
      postApiJson(
        "/users/change-full-name/",
        { full_name: fullName },
        { method: "PUT" },
      )
        .then(() => {
          this.fullName = fullName
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    requestEmailChange(newEmail, onSuccess, onFail) {
      postApiJson(
        "/users/request-email-change/",
        { new_email: newEmail },
        { method: "POST" },
      )
        .then(() => {
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    changeEmail(newEmail, token, password, onSuccess, onFail) {
      postApiJson(
        "/users/change-email/",
        { token, password },
        { method: "PUT" },
      )
        .then(() => {
          this.email = newEmail
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    changePassword(currentPassword, newPassword, onSuccess, onFail) {
      postApiJson(
        "/users/change-password/",
        { current_password: currentPassword, new_password: newPassword },
        { method: "PUT" },
      )
        .then(() => {
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
  },
  persist: true,
})
