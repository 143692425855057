<template>
  <div class="min-h-screen md:h-screen">
    <!-- Banner -->
    <BannerComponent
      v-if="notificationStore.banner"
      v-bind="notificationStore.banner"
    />
    <div
      id="main-layout"
      class="grid min-h-screen grid-cols-1 grid-rows-[auto,1fr] md:h-full md:grid-cols-[auto,1fr] md:grid-rows-1"
    >
      <!-- Menu -->
      <MenuComponent :items="menuItems" />
      <!-- Content -->
      <div class="grid h-full w-full grid-cols-1 grid-rows-[auto,1fr]">
        <StatusBarComponent
          v-if="!hideStatusbar && sessionStore.isAuthenticated"
        />
        <div class="overflow-y-scroll">
          <main
            ref="scrollContainer"
            class="grid h-full grid-cols-1 grid-rows-[1fr,auto]"
          >
            <div class="px-4 py-6 md:px-10">
              <!-- Errors container -->
              <TransitionGroup
                name="notification"
                tag="ul"
                class="sticky top-0 z-10 grid gap-4 xl:grid-cols-2 min-[2048px]:grid-cols-3"
                :class="notificationStore.errors.length ? 'mb-6' : ''"
              >
                <li
                  v-for="notification in notificationStore.errors"
                  :key="notification.uid"
                >
                  <ErrorComponent v-bind="notification" />
                </li>
              </TransitionGroup>
              <!-- Current view -->
              <LoadingWrapper :loading="sessionStore.loading">
                <RouterView v-if="showRouterView" />
              </LoadingWrapper>
            </div>
            <!-- Footer -->
            <footer class="w-full py-6 text-center text-sm text-gray-300">
              <p>
                Versie <span class="italic">{{ version }}</span>
              </p>
              <p>© {{ getYear(startOfToday()) }} Repowered</p>
            </footer>
          </main>
        </div>
      </div>
    </div>
    <!-- Toasts container -->
    <div class="absolute bottom-4 right-4 w-[360px] space-y-4">
      <TransitionGroup name="notification">
        <ToastComponent
          v-for="notification in notificationStore.toasts"
          :key="notification.uid"
          v-bind="notification"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from "vue"
import { RouterView, useRoute, useRouter } from "vue-router"
import { startOfToday, getYear } from "date-fns"
import { onlyPublicRoutes, publicRoutes } from "@/router.js"
import useNotificationStore from "@/stores/notificationStore.js"
import useSessionStore from "@/stores/sessionStore.js"
import useUserStore from "@/stores/userStore"
import useChangelogStore from "@/stores/changelogStore.js"
import { LoadingWrapper } from "@repowerednl/ui-component-library"
import BannerComponent from "@/components/notifications/BannerComponent.vue"
import ErrorComponent from "@/components/notifications/ErrorComponent.vue"
import ToastComponent from "@/components/notifications/ToastComponent.vue"
import MenuComponent from "@/components/navigation/MenuComponent.vue"
import StatusBarComponent from "@/components/navigation/StatusBarComponent.vue"

const notificationStore = useNotificationStore()
const sessionStore = useSessionStore()
const userStore = useUserStore()
const changelogStore = useChangelogStore()
const route = useRoute()
const router = useRouter()
const scrollContainer = ref(null)
// eslint-disable-next-line no-undef
const version = __APP_VERSION__
const showRouterView = ref(false)

const menuItems = computed(() => {
  if (!sessionStore.isAuthenticated) {
    return [
      {
        name: "Inloggen",
        icon: "user",
        section: "Account",
        route: "login",
      },
    ]
  }
  return [
    {
      name: "Dashboard",
      icon: "dashboard-3",
      section: "Assets",
      route: "dashboard",
    },
    {
      name: "Assets",
      icon: "flashlight",
      section: "Assets",
      route: "assets",
    },
    {
      name: "Resultaten",
      icon: "line-chart",
      section: "Assets",
      route: "results",
    },
    // {
    //   name: "Market orders",
    //   icon: "time",
    //   section: "Assets",
    //   route: "market-orders",
    // },
    {
      name: "Marktinzichten",
      icon: "stock",
      section: "Markten",
      route: "markets",
    },
    {
      name: "Mijn Repowered",
      icon: "user",
      section: "Account",
      route: "account",
    },
    {
      name: "Uitloggen",
      icon: "logout-box",
      section: "Account",
      route: "logout",
    },
    {
      name: "Nieuws en contact",
      icon: "information",
      section: "Help",
      route: "news",
      ping: changelogStore.notify,
    },
  ]
})

const hideStatusbar = computed(() => {
  return ["login", "logout"].includes(route.name)
})

/**
 * 'showRouterView' is only set to true after the router change is awaited.
 * This prevents possible API calls from being made before the route change is
 * completed.
 */
async function onSessionAuthenticated() {
  userStore.loadMeteringPoints()
  changelogStore.loadDateUpdated()
  if (onlyPublicRoutes.includes(route.name)) {
    await router.push({ name: "dashboard" })
  }
  showRouterView.value = true
  notificationStore.pushToast("Je bent ingelogd.")
}

/**
 * 'showRouterView' is only set to true after the router change is awaited.
 * This prevents possible API calls from being made before the route change is
 * completed.
 */
async function onSessionUnauthenticated() {
  if (!publicRoutes.includes(route.name)) {
    await router.push({ name: "login" })
  }
  showRouterView.value = true
}

function onSessionFail(error) {
  onSessionUnauthenticated()
  notificationStore.pushError(
    "Fout bij het ophalen van sessie",
    `Sessie kon niet worden opgehaald. Probeer in te loggen of probeer het later opnieuw. (code: ${error.code})`,
  )
}

// Reset container scroll position when the route changes
watch(
  () => route.name,
  () => {
    scrollContainer.value.scrollTo(0, 0)
  },
)

onBeforeMount(() => {
  sessionStore.requestCsrfToken()
  sessionStore.getSession(
    onSessionAuthenticated,
    onSessionUnauthenticated,
    onSessionFail,
  )
})
</script>

<style>
/*
  The DatePicker modal is teleported to this component, which requires the
  following CSS variables to be set here specifically.
*/
* {
  --dp-text-color: theme("colors.gray[900]");
  --dp-menu-border-color: theme("colors.gray[200]");
  --dp-border-color: theme("colors.gray[200]");
  --dp-primary-color: theme("colors.primary[500]");
  --dp-secondary-color: theme("colors.gray[300]");
  --dp-icon-color: theme("colors.gray[900]");
  --dp-hover-color: theme("colors.primary[50]");
  --dp-hover-text-color: theme("colors.primary[500]");
  --dp-range-between-dates-background-color: theme("colors.primary.50");
  --dp-range-between-border-color: theme("colors.primary.50");
  --dp-range-between-dates-text-color: theme("colors.primary.500");
}
</style>

<style>
.notification-move {
  transition: all 0.3s ease;
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/*
  Ensure leaving items are taken out of layout flow so that moving animations
  can be calculated correctly.
*/
.notification-leave-active {
  position: absolute;
}
</style>
