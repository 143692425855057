<template>
  <TextTitle>Gegevens</TextTitle>
  <MeteringPointCard
    :metering-point="toolbarStore.selection"
    class="p-4 md:p-6"
  >
    <div class="flex flex-col justify-between gap-8 lg:flex-row">
      <div
        class="grid h-fit flex-shrink-0 grid-cols-[auto_auto] gap-x-4 gap-y-2 odd:*:text-gray-300 even:*:font-medium"
      >
        <p>Naam</p>
        <p>{{ asset.name }}</p>
        <p>Status</p>
        <div class="flex items-center">
          <StatusIndicatorComponent
            :asset="toolbarStore.selection"
            no-tooltip
            class="mr-1 size-4"
          />
          <p>{{ $p(asset.status.description_key) }}</p>
        </div>
        <p>EAN</p>
        <p>{{ asset.allocation_point_ean }}</p>
        <p>Locatie</p>
        <p>{{ asset.address.address }}, {{ asset.address.city }}</p>
        <p>Type</p>
        <div class="flex items-center gap-1.5">
          <BaseIcon :name="toolbarStore.selection.icon" filled />
          <p>{{ phraseMapType[asset.type] ?? asset.type }}</p>
        </div>
        <template v-if="asset.type === 'SolarPark'">
          <p>SDE fasebedrag</p>
          <p>{{ formatCurrency(asset.sde_component) }} per MWh</p>
          <p>DC vermogen</p>
          <p>{{ formatUnit(asset.dc_capacity * 1000, "Wp") }}</p>
        </template>
      </div>
      <div class="flex flex-col gap-4 lg:max-w-96">
        <img
          v-if="mapImage"
          class="aspect-[3/2] rounded-lg"
          :src="mapImage"
          alt="Map"
        />
        <a
          :href="`https://www.google.com/maps/@?api=1&map_action=map&center=${asset.address.latitude},${asset.address.longitude}&zoom=17&basemap=satellite`"
          target="_blank"
        >
          <BaseButton color="secondary" class="lg:ml-auto">
            Bekijk op Google Maps
            <BaseIcon name="external-link" class="ml-1" />
          </BaseButton>
        </a>
      </div>
    </div>
  </MeteringPointCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import $p from "@/services/phraseService.js"
import { formatCurrency, formatUnit } from "@/services/formatterService.js"
import useToolbarStore from "@/stores/toolbarStore.js"
import { staticMap } from "@/services/mapboxApiService.js"
import {
  TextTitle,
  BaseIcon,
  BaseButton,
} from "@repowerednl/ui-component-library"
import MeteringPointCard from "@/components/panels/MeteringPointCard.vue"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const toolbarStore = useToolbarStore()
const phraseMapType = {
  SolarPark: "Zonnepark",
  Battery: "Batterij",
  Consumer: "Verbruik",
}
const mapImage = ref()

const asset = computed(() => {
  return toolbarStore.selection.properties
})

watch(
  () => toolbarStore.selection,
  () => {
    mapImage.value = null
    if (toolbarStore.selection) {
      staticMap(
        asset.value.address.longitude,
        asset.value.address.latitude,
        (blob) => {
          mapImage.value = URL.createObjectURL(blob)
        },
      )
    }
  },
  { immediate: true },
)
</script>
