<template>
  <div class="flex flex-col gap-4">
    <!-- Chart panel -->
    <AssetChartPanel
      :metering-point="toolbarStore.selection"
      :date-range="toolbarStore.dateRange"
      show-stats
      show-annotations
    />
  </div>
</template>

<script setup>
import useToolbarStore from "@/stores/toolbarStore.js"
import AssetChartPanel from "@/components/panels/AssetChartPanel.vue"

const toolbarStore = useToolbarStore()
</script>
