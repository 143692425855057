import { defineStore } from "pinia"
import useWikiStore from "@/stores/wikiStore.js"
import { isBefore, differenceInDays } from "date-fns"

const MAX_NOTIFY_AGE = 14

/**
 * Keeps track of whether the user should be notified of a changelog update.
 */
export default defineStore("changelogStore", {
  state: () => ({
    dateUpdated: null,
    dateSeen: false,
  }),
  getters: {
    // Only notify if not seen yet and the update is recent
    notify() {
      return (
        this.dateUpdated &&
        (!this.dateSeen || isBefore(this.dateSeen, this.dateUpdated)) &&
        differenceInDays(new Date(), this.dateUpdated) < MAX_NOTIFY_AGE
      )
    },
  },
  actions: {
    loadDateUpdated() {
      useWikiStore().loadPage(
        "/release-notes",
        (page) => (this.dateUpdated = page.date),
      )
    },
    seen() {
      this.dateSeen = new Date()
    },
  },
  persist: true,
})
