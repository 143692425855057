<template>
  <div class="box-content flex h-16 border-b border-gray-100 bg-white">
    <!-- Asset button row -->
    <div class="relative flex-1 overflow-hidden">
      <!-- Buttons -->
      <div
        ref="scrollContainer"
        class="scrollbar-hide flex h-full w-full items-center gap-2 overflow-auto px-4 md:px-10"
        @mousewheel="onMouseWheel"
      >
        <LoadingWrapper
          :error="assetsStore.loadingFailed"
          :loading="assetsStore.loading"
          compact
        >
          <!-- Search button -->
          <BaseButton
            v-if="assetsStore.assets.length > 0"
            color="secondary"
            small
            @click="onSearchClicked"
          >
            <BaseIcon name="search" class="text-gray-900" />
          </BaseButton>
          <!-- Asset buttons -->
          <BaseButton
            v-for="asset in assetsStore.assets"
            :key="asset.id"
            color="secondary"
            small
            @click="onAssetClicked(asset)"
          >
            <StatusIndicatorComponent :asset no-tooltip class="size-4" />
            <span class="mx-1">{{ asset.name }}</span>
            <BaseIcon :name="asset.icon" filled class="text-gray-300" />
          </BaseButton>
        </LoadingWrapper>
      </div>
      <!-- Gradients to indicate more content -->
      <div
        class="pointer-events-none absolute left-0 top-0 h-full w-5 bg-gradient-to-r from-white via-white via-0% to-white/0 md:w-10 md:via-25%"
      />
      <div
        class="pointer-events-none absolute right-0 top-0 h-full w-5 bg-gradient-to-r from-white/0 via-white via-100% to-white md:w-10 md:via-75%"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useToolbarStore from "@/stores/toolbarStore.js"
import useAssetsStore from "@/stores/repower/assetsStore.js"
import {
  LoadingWrapper,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const route = useRoute()
const router = useRouter()
const toolbarStore = useToolbarStore()
const assetsStore = useAssetsStore()
const scrollContainer = ref(null)

// Scroll asset list horizontally when the mouse wheel is used
function onMouseWheel(event) {
  scrollContainer.value.scrollLeft += event.deltaY / 2
}

function onSearchClicked() {
  router.push({ name: "results", query: { search: null } })
}

function onAssetClicked(asset) {
  toolbarStore.selection = asset
  router.push({ name: "assets-analysis" })
}

// Reset asset bar scroll position when the route changes
watch(
  () => route.name,
  () => {
    if (scrollContainer.value) {
      scrollContainer.value.scrollTo(0, 0)
    }
  },
)
</script>
