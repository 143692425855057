// TODO: This is solving the issue of non-standardized data from Repower. It
// would be better to have a standardized data format.

/**
 * Maps keys found in Repower record streams to categories needed in the UI.
 * Each key has an object with rules for aggregation:
 * - sign: 1 or -1, indicating if the value should be added or subtracted from
 *  the category.
 * - excl: undefined or a number, indicating an exclusion group. Only one key
 * from each group is added to the category. The order within the keyMap
 * category determines the priority.
 */
const keyMap = {
  allocation: {
    "Allocation Sell (kWh)": { sign: 1, excl: 1 },
    "Allocation Buy (kWh)": { sign: -1 },
    "Allocation (kWh)": { sign: 1, excl: 1 },
    "Allocation Feed-in (kWh)": { sign: 1 },
    "Allocation Take-off (kWh)": { sign: -1 },
  },
  nomination: {
    "Nomination Sell (kWh)": { sign: 1 },
    "Nomination Buy (kWh)": { sign: -1 },
  },
  revenue: {
    Total: { sign: 1 },
  },
  isFinal: {
    is_final: { sign: 1 },
  },
}

/**
 * Categorizes records into categories from the keyMap. Input is an object
 * with keys and values, and output is an object with categories and their
 * aggregated values.
 */
export function categorizeRecords(values) {
  const result = {}
  for (const [targetKey, sourceKeys] of Object.entries(keyMap)) {
    const exclusions = []
    for (const [key, specs] of Object.entries(sourceKeys)) {
      if (key in values && !exclusions.includes(specs.excl)) {
        result[targetKey] = (result[targetKey] ?? 0) + values[key] * specs.sign
        if ("excl" in specs) {
          exclusions.push(specs.excl)
        }
      }
    }
  }
  return result
}
