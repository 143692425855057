import { defineStore } from "pinia"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

export default defineStore("marketPricesStore", {
  state: () => ({
    loading: false,
    dayAheadPrices: makeDataFetchObject(),
    balanceDeltaPrices: makeDataFetchObject(),
  }),
  actions: {
    async loadDayAheadPrices(dateStart, dateEnd, onSuccess, onFail) {
      const params = prepareDataFetch(
        this.dayAheadPrices,
        null,
        dateStart,
        dateEnd,
        null,
        null,
        onSuccess,
      )
      if (!params) return
      const url = `/v2/market-prices/day-ahead/?${params}`
      const promise = fetchApiJson(url)
      handleDataFetchPromise(
        this.dayAheadPrices,
        promise,
        params,
        onSuccess,
        onFail,
      )
    },
    async loadBalanceDeltaPrices(dateStart, dateEnd, onSuccess, onFail) {
      const params = prepareDataFetch(
        this.balanceDeltaPrices,
        null,
        dateStart,
        dateEnd,
        null,
        null,
        onSuccess,
      )
      if (!params) return
      const url = `/v2/market-prices/balance-delta/?${params}`
      const promise = fetchApiJson(url)
      handleDataFetchPromise(
        this.balanceDeltaPrices,
        promise,
        params,
        onSuccess,
        onFail,
      )
    },
  },
})
