import { defineStore } from "pinia"
import { startOfToday } from "date-fns"

export default defineStore("toolbarStore", {
  state: () => ({
    selection: null,
    dateRange: [startOfToday(), startOfToday()],
    mode: "energy",
  }),
  actions: {
    setDateRange(dateRange) {
      this.dateRange = dateRange
    },
    setMode(mode) {
      this.mode = mode
    },
  },
})
