<template>
  <div class="relative">
    <BaseButton color="secondary" class="w-full md:w-auto" @click="openMenu">
      <slot v-if="selectedItem" :name="selectedItem.label">
        {{ selectedItem.label }}
      </slot>
      <div v-else class="text-primary">
        <slot>Maak een selectie</slot>
      </div>
    </BaseButton>
    <EscapeOverlay
      :active="menuVisible"
      :shaded="shadedOverlay"
      class="z-10"
      @escape="onCancel"
    />

    <BaseCard
      v-if="menuVisible"
      class="absolute z-20 w-full translate-y-2 md:w-96"
      :class="{ 'right-0': alignRight }"
    >
      <InputItemsFilter
        ref="filterListRef"
        v-model="pendingSelection"
        :items="items"
        :disabled-parents="disabledParents"
      >
        <!-- Pass any slot content to InputItemsFilter slots of the same name -->
        <template v-for="(_, name) in $slots" #[name]="slotProps">
          <slot :name="name" v-bind="slotProps" />
        </template>
      </InputItemsFilter>
      <BaseDivider />
      <div class="flex items-center justify-between gap-2 p-4">
        <BaseButton color="primary" small @click="onConfirm">
          Bevestigen
        </BaseButton>
        <BaseButton color="secondary" small @click="onCancel">
          Annuleren
        </BaseButton>
      </div>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import {
  BaseCard,
  BaseButton,
  BaseDivider,
  EscapeOverlay,
  InputItemsFilter,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  /**
   * Must have the following structure:
   * [
   *   { label: "label", value: "value" },
   *   ...
   * ]
   *
   * An item can optionally have a 'parent' key that references another item's
   * label key. This will group the item under the referenced item.
   *
   * Items can be hidden by adding a 'hidden' boolean property.
   */
  items: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((item) => {
        return Object.hasOwn(item, "label") && Object.hasOwn(item, "value")
      })
    },
  },
  /**
   * Disable selection of parent items, meaning they just act as a group header.
   */
  disabledParents: Boolean,
  /**
   * Show a shaded overlay when the menu is open, drawing attention to the menu.
   */
  shadedOverlay: {
    type: Boolean,
    default: false,
  },
  alignRight: Boolean,
})

// eslint-disable-next-line vue/require-prop-types
const confirmedSelection = defineModel({
  default: null,
})

const emit = defineEmits(["confirmed", "cancelled"])

defineExpose({
  openMenu,
})

const filterListRef = ref(null)
const pendingSelection = ref([])
const menuVisible = ref(false)

/**
 * Array of selected item labels in 'props.items' order
 */
const selectedItem = computed(() => {
  return props.items.find((item) => item.value === confirmedSelection.value)
})

function openMenu() {
  menuVisible.value = true
}

function onConfirm() {
  menuVisible.value = false
  // Only apply if selection has changed
  if (pendingSelection.value[0] !== confirmedSelection.value) {
    confirmedSelection.value = pendingSelection.value[0]
    emit("confirmed")
    return
  }
  emit("cancelled")
}

function onCancel() {
  menuVisible.value = false
  emit("cancelled")
}

/**
 * Update pending selection when confirmed selection changes
 */
watch(
  confirmedSelection,
  (newSelection) => (pendingSelection.value = [newSelection]),
  { immediate: true },
)

/**
 * Focus search input when menu is opened
 */
watch(filterListRef, (newRef) => {
  if (newRef) {
    filterListRef.value.focusSearch()
  }
})
</script>
