// Should be replaced byVue i18n or something similar

export default function getPhrase(key) {
  if (key in phrases) {
    return phrases[key]
  } else {
    console.warn(`Phrase with key "${key}" not found`)
    return key
  }
}

const phrases = {
  "status-unknown": "Asset status onbekend",
  "status-offline": "Asset is offline",
  "status-online": "Asset is online",
  "status-active-steering-curtailment": "Zonnepark wordt gecurtaild",
  "status-active-steering-charging": "Batterij is aan het opladen",
  "status-active-steering-discharging": "Batterij is aan het ontladen",
  "status-active-steering-not-specified": "Asset wordt aangestuurd",
  "status-error": "Er is een fout opgetreden",
}
