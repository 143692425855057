import { defineStore } from "pinia"
import useUid from "@/composables/uid.js"

/**
 * Notification 'content' is an object with the following structure:
 *   {
 *     title: <string>,
 *     message: <string>,
 *     action: <function>,
 *     actionLabel: <string>,
 *   }
 * Only the title field is required. The actionLabel field defaults to a string
 * defined in the notification components if an action is specified.
 *
 * The components that display notifications are:
 * - ErrorComponent
 * - ToastComponent
 * - BannerComponent
 *
 * Notifications with the same key will be grouped together. These will be
 * displayed as a single notification with a count of the number of occurences.
 * The contents of the first notification with the key will be displayed.
 */

export default defineStore("notificationStore", {
  state: () => ({
    notifications: {},
    keys: {},
  }),
  getters: {
    banner: (state) => {
      return Object.values(state.notifications).findLast(
        (notification) => notification.type === "banner",
      )?.content
    },
    errors: (state) => {
      return Object.values(state.notifications)
        .filter(
          (notification, index) => notification.type === "error" && index <= 4,
        )
        .map((notification) => notification.content)
    },
    toasts: (state) => {
      return Object.values(state.notifications)
        .filter(
          (notification, index) => notification.type === "toast" && index <= 3,
        )
        .map((notification) => notification.content)
    },
  },
  actions: {
    push(content, type, timeout, key) {
      const uid = useUid()
      const keyObj = key ? (this.keys[key] = this.keys[key] || { uid }) : null
      // Push notification to stack, skip if existing key
      if (!key || keyObj.uid === uid) {
        this.notifications[uid] = {
          content: { ...content, uid },
          type,
        }
      } else {
        const content = this.notifications[keyObj.uid].content
        content.count = (content.count ?? 1) + 1
      }
      // (Re)set timeout to remove notification
      if (key) {
        clearTimeout(keyObj.timer)
        keyObj.timer = null
      }
      if (timeout) {
        const timer = setTimeout(() => {
          this.remove(key ? keyObj.uid : uid)
        }, timeout)
        if (key) {
          keyObj.timer = timer
        }
      }
    },
    remove(uid) {
      delete this.notifications[uid]
      const key = Object.keys(this.keys).find(
        (key) => this.keys[key].uid === uid,
      )
      if (key) {
        delete this.keys[key]
      }
    },
    pushContentBanner(content, key) {
      this.push(content, "banner", null, key)
    },
    pushContentError(content, key) {
      this.push(content, "error", 10000, key)
    },
    pushContentToast(content, key) {
      this.push(content, "toast", 5000, key)
    },
    pushBanner(title, message, key) {
      this.pushContentBanner({ title, message }, key)
    },
    pushError(title, message, key) {
      this.pushContentError({ title, message }, key)
    },
    pushToast(title, message, key) {
      this.pushContentToast({ title, message }, key)
    },
  },
})
