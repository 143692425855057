<template>
  <BaseCard class="max-h-[720px] overflow-x-auto">
    <LoadingWrapper
      v-if="meteringPoint"
      :error="loadingFailed"
      :loading="loading"
    >
      <BaseTable
        :columns="columns"
        :rows="rows"
        :precision="2"
        class="min-h-24"
      >
        <!-- Add header tooltips -->
        <template
          v-for="(column, index) in Object.keys(columns)"
          :key="column"
          #[column]="{ header }"
        >
          {{ header }}
          <WikiTooltipComponent
            v-if="columnWikiPaths[column]"
            class="top-0.5"
            :path="columnWikiPaths[column]"
            :position="
              index === Object.keys(columns).length - 1
                ? 'bottom left'
                : 'bottom'
            "
          />
        </template>
        <!-- Bold total column -->
        <template #Total-cell="{ row }">
          <div class="font-bold text-gray-500">
            {{ formatNumber(row.Total, 2) }}
          </div>
        </template>
        <template #Total-summary="{ row }">
          <div class="font-bold text-gray-500">
            {{ formatNumber(row.Total, 2) }}
          </div>
        </template>
        <!-- Italic summary cell -->
        <template #datetime-summary="{ row }">
          <span class="italic">
            {{ row.datetime }}
          </span>
        </template>
        <!-- Format dates and add badges to datetime cells -->
        <template #datetime-cell="{ row }">
          <div class="flex gap-1">
            <span>{{ format(row.datetime, dateFormat[step]) }}</span>
            <BaseBadge
              v-if="isToday(row.datetime) && step !== 'minute'"
              color="primary"
            >
              Vandaag
            </BaseBadge>
            <BaseBadge
              v-else-if="!row.is_final"
              class="!bg-energy-50 !text-energy-900"
            >
              Voorlopig
            </BaseBadge>
          </div>
        </template>
      </BaseTable>
    </LoadingWrapper>
    <div v-else class="flex items-center justify-center p-4 italic md:p-6">
      <p>Selecteer een asset of portfolio voor een tabel van de resultaten.</p>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  isToday,
  format,
} from "date-fns"
import { formatNumber } from "@/services/formatterService.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseCard,
  BaseBadge,
  BaseTable,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /**
   * Can be either an asset or a portfolio, but no data is shown for portfolios.
   */
  meteringPoint: {
    type: Object,
    default: null,
  },
  /**
   * Array of two Date objects; the last day is included in the range.
   */
  dateRange: {
    type: Array,
    required: true,
  },
  /**
   * Determines which data series are shown/hidden.
   */
  mode: {
    required: true,
    validator: (value) => ["energy", "finance", "both"].includes(value),
  },
})

const notificationStore = useNotificationStore()
const collection = ref()
const loading = ref(false)
const loadingFailed = ref(false)

/**
 * Matches wiki paths to column names.
 *
 * TODO: The wiki paths should be specified in the backend instead, since
 * streams are arbitrary.
 */
const wikiPathPatterns = [
  [/allocation/i, "/platform/results/allocation"],
  [/curtailment/i, "/platform/results/curtailment"],
  [/day ahead/i, "/platform/results/day-ahead"],
  [/imbalance/i, "/platform/results/imbalance"],
  [/nomination/i, "/platform/results/day-ahead"],
  [/fee/i, "/platform/results/fees"],
]

const freq = {
  minute: "15min",
  day: "1D",
  month: "MS",
  year: "YS",
}

const dateFormat = {
  minute: "PPp",
  day: "PP",
  month: "MMMM y",
  year: "y",
}

const step = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  const months =
    1 + differenceInCalendarMonths(props.dateRange[1], props.dateRange[0])
  if (days < 5) {
    return "minute"
  } else if (days < 32) {
    return "day"
  } else if (months < 25) {
    return "month"
  } else {
    return "year"
  }
})

const columns = computed(() => {
  if (!collection.value) {
    return {}
  }
  const columns = {
    datetime: {
      label: "Datum",
    },
  }
  collection.value.streams.forEach((stream) => {
    if (stream.type === "volume" && props.mode === "finance") return
    if (["financial", "total"].includes(stream.type) && props.mode === "energy")
      return
    columns[stream.label] = {
      label: stream.label,
      classes: "text-right",
    }
  })
  return columns
})

const columnWikiPaths = computed(() => {
  const paths = {}
  for (const column in columns.value) {
    for (const [pattern, path] of wikiPathPatterns) {
      if (pattern.test(column)) {
        paths[column] = path
        break
      }
    }
  }
  return paths
})

const rows = computed(() => {
  if (!collection.value) {
    return []
  }
  const { Total, ...data } = collection.value.data
  // Top summary row
  const rows = [
    {
      datetime: "Totaal",
      _id: "summary",
      ...Total,
    },
  ]
  // Data rows
  return rows.concat(
    Object.entries(data).map(([datetime, values]) => {
      return {
        datetime: datetime,
        _id: "cell",
        ...values,
      }
    }),
  )
})

/**
 * Stores the record stream collection with 'Repowered' as origin.
 */
function onNewRecordStreamCollection(newCollecion) {
  collection.value = newCollecion
  loading.value = false
}

function onLoadCollectionFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van resultaten",
    `De resultaten van een asset konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-asset-results-error",
  )
}

watch(
  [() => props.meteringPoint, () => props.dateRange],
  ([meteringPoint, dateRange]) => {
    if (!meteringPoint) return
    loading.value = true
    loadingFailed.value = false
    collection.value = null
    meteringPoint.loadRecordStreamCollection(
      freq[step.value],
      ...dateRange,
      false,
      false,
      onNewRecordStreamCollection,
      onLoadCollectionFailed,
    )
  },
)
</script>
