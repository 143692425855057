<template>
  <div class="w-full gap-4 md:flex">
    <!-- Asset selection -->
    <FilterMenuComponent
      ref="filterMenuRef"
      v-model="toolbarStore.selection"
      :items="filterItems"
      :disabled-parents="noPortfolios"
      :shaded-overlay="'search' in $route.query"
      @confirmed="onFilterConfirmed"
      @cancelled="onFilterCancelled"
    >
      <!-- Enrich asset names with status and icon -->
      <template
        v-for="asset in assetsStore.assets"
        :key="asset.id"
        #[asset.name]
      >
        <StatusIndicatorComponent :asset class="size-4" />
        <span class="mx-2">{{ asset.name }}</span>
        <BaseBadge class="!bg-energy-50 !text-energy-900">
          <BaseIcon :name="asset.icon" filled />
        </BaseBadge>
      </template>
      <!-- Undo portfolio label prefix -->
      <template
        v-for="portfolio in portfoliosStore.portfolios"
        :key="portfolio.id"
        #[`PORTFOLIO-${portfolio.name}`]
      >
        {{ portfolio.name }}
      </template>
    </FilterMenuComponent>
    <!-- Date selection -->
    <DatePicker
      v-if="!noDatepicker"
      v-model="toolbarStore.dateRange"
      teleport="#main-layout"
      class="my-2 md:my-0 md:w-80"
    />
    <!-- Filler -->
    <div class="flex-1" />
    <!-- Mode selection -->
    <RadioButton
      v-if="!noMode"
      :initial-value="toolbarStore.mode"
      class="mb-2 grid grid-cols-3 md:mb-0 md:flex"
      :items="radioItems"
      @selection-changed="onModeChanged"
    >
      <template #energy="{ label }">
        <BaseIcon name="flashlight" filled class="mr-1" />
        {{ label }}
      </template>
      <template #finance="{ label }">
        <BaseIcon name="money-euro-circle" filled class="mr-1 text-lg" />
        {{ label }}
      </template>
    </RadioButton>
    <!-- Other buttons -->
    <slot />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useToolbarStore from "@/stores/toolbarStore.js"
import useAssetsStore from "@/stores/repower/assetsStore.js"
import usePortfoliosStore from "@/stores/repower/portfoliosStore.js"
import {
  BaseBadge,
  BaseIcon,
  DatePicker,
  RadioButton,
} from "@repowerednl/ui-component-library"
import FilterMenuComponent from "@/components/input/FilterMenuComponent.vue"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const props = defineProps({
  assetsFilter: {
    type: Function,
    default: null,
  },
  noPortfolios: Boolean,
  noDatepicker: Boolean,
  noMode: Boolean,
})

const route = useRoute()
const router = useRouter()
const toolbarStore = useToolbarStore()
const assetsStore = useAssetsStore()
const portfoliosStore = usePortfoliosStore()
const filterMenuRef = ref()

const filterItems = computed(() => {
  // Prefix portfolio labels to distinguish from assets that have the same name
  let portfolioItems = portfoliosStore.portfolios.map((portfolio) => {
    return {
      label: `PORTFOLIO-${portfolio.name}`,
      value: portfolio,
    }
  })
  const filteredAssets = props.assetsFilter
    ? assetsStore.assets.filter(props.assetsFilter)
    : assetsStore.assets
  const assetItems = filteredAssets.map((asset) => {
    const portfolio = portfoliosStore.find(asset.portfolioId)
    return {
      label: asset.name,
      value: asset,
      parent: portfolio ? `PORTFOLIO-${portfolio.name}` : null,
    }
  })
  if (props.noPortfolios) {
    // Remove portfolio items without a child asset
    portfolioItems = portfolioItems.filter((item) =>
      assetItems.some((asset) => asset.parent === item.label),
    )
  }
  return [...portfolioItems, ...assetItems]
})

const radioItems = computed(() => [
  { label: "Energie", value: "energy" },
  { label: "Financieel", value: "finance" },
  { label: "Beide", value: "both" },
])

function onFilterConfirmed() {
  router.replace({ query: null })
}

function onFilterCancelled() {
  router.replace({ query: null })
}

function onModeChanged(selection) {
  toolbarStore.setMode(selection)
}

/**
 * Open the menu when the 'search' URL query is present
 */
watch(
  [() => route.query, () => filterMenuRef.value],
  ([query, menuRef]) => {
    if (menuRef && "search" in query) {
      filterMenuRef.value.openMenu()
    }
  },
  { immediate: true },
)
</script>
