<template>
  <div
    class="mx-auto flex max-w-[480px] flex-col items-center gap-4 md:mt-6 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <template v-if="!loggedOut">
        <TextTitle>Je wordt nu uitgelogd</TextTitle>
        <LoadingWrapper loading />
      </template>
      <template v-else>
        <TextTitle>Je bent uitgelogd</TextTitle>
        <p>Je wordt automatisch doorgestuurd naar het inlogscherm.</p>
      </template>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue"
import { useRouter } from "vue-router"
import useNotificationStore from "@/stores/notificationStore.js"
import useUserStore from "@/stores/userStore.js"
import useSessionStore from "@/stores/sessionStore.js"
import {
  TextTitle,
  BaseCard,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"

const router = useRouter()
const notificationStore = useNotificationStore()
const userStore = useUserStore()
const sessionStore = useSessionStore()
const loggedOut = ref(false)
let timer = null

function onLogoutSuccess() {
  loggedOut.value = true
  timer = setTimeout(() => {
    router.replace({ name: "login" })
  }, 4000)
}

function onLogoutFail(error) {
  if (sessionStore.isAuthenticated) {
    router.replace({ name: "dashboard" })
    notificationStore.pushError(
      "Uitloggen niet mogelijk",
      `Er ging iets mis bij het uitloggen. Vernieuw de pagina en probeer het opnieuw. (code: ${error.code})`,
    )
  } else {
    router.replace({ name: "login" })
  }
}

onMounted(() => {
  userStore.logout(onLogoutSuccess, onLogoutFail)
})

onUnmounted(() => {
  clearTimeout(timer)
})
</script>
