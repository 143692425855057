<template>
  <div class="flex flex-col gap-4">
    <TextHeader>Nieuws en contact</TextHeader>
    <div class="flex flex-col gap-10 xl:flex-row">
      <div class="flex-1">
        <!-- Risks due to v-html are mitigated with DOMPurify -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="wiki release-notes" v-html="changelogContent" />
      </div>
      <BaseCard class="flex h-fit flex-1 flex-col gap-4 p-4 md:p-6">
        <TextTitle>Contact</TextTitle>
        <p>
          Uitgebreide hulpfuncties zijn in ontwikkeling. Vragen of opmerkingen?
          Neem contact op met Repowered.
        </p>
        <a href="mailto:support@repowered.nl">
          <BaseButton color="secondary" class="w-fit">
            Contact opnemen
          </BaseButton>
        </a>
      </BaseCard>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import useWikiStore from "@/stores/wikiStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import useChangelogStore from "@/stores/changelogStore.js"
import {
  TextHeader,
  TextTitle,
  BaseCard,
  BaseButton,
} from "@repowerednl/ui-component-library"

const wikiStore = useWikiStore()
const notificationStore = useNotificationStore()
const changelogStore = useChangelogStore()
const changelogContent = ref("")

onMounted(() => {
  wikiStore.loadPage(
    "/release-notes",
    (page) => {
      changelogContent.value = page.content
      changelogStore.seen()
    },
    (error) => {
      notificationStore.pushError(
        "Documentatie niet beschikbaar",
        `De release notes konden niet worden opgehaald (code: ${error.code})`,
        "loading-wiki-failed",
      )
    },
  )
})
</script>

<style>
/* Overwrite some styles from the wiki class in WikiTooltipComponent */
.release-notes > h1 {
  @apply !mb-0 !border-none !font-medium;
}

.release-notes > h1:first-of-type {
  @apply mt-4;
}
</style>
