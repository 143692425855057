import { defineStore } from "pinia"
import { fetchApiJson } from "@/services/repowerAPIService.js"
import useSinglePortfolioStoreFactory from "@/stores/repower/singlePortfolioStoreFactory.js"

export default defineStore("portfoliosStore", {
  state: () => ({
    loading: false,
    loadingFailed: false,
    portfolios: [],
  }),
  actions: {
    find(portfolioId) {
      return this.portfolios.find((p) => p.id === portfolioId)
    },
    loadPortfolios(onSuccess, onFail) {
      this.loading = true
      fetchApiJson("/v2/portfolios/")
        .then((data) => {
          this.portfolios = []
          for (const portfolio of data) {
            const singlePortfolioStore = useSinglePortfolioStoreFactory(
              portfolio.id,
            )
            singlePortfolioStore.setPortfolio(portfolio)
            this.portfolios.push(singlePortfolioStore)
          }
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          this.loadingFailed = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
