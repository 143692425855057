function mapboxFetch(path, options) {
  return fetch(`https://api.mapbox.com${path}`, options)
    .then((response) => {
      if (response.status === 200) {
        return response.blob()
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(
        `Failed to fetch from Mapbox API: '${path}' (code: ${error.code})`,
      )
      throw error
    })
}

export function staticMap(longitude, latitude, onSuccess, onFail) {
  const zoom = 15
  const bearing = 0
  const width = 450
  const height = 300
  const token = import.meta.env.VITE_MAPBOX_TOKEN
  const path = `/styles/v1/mapbox/satellite-v9/static/${longitude},${latitude},${zoom},${bearing}/${width}x${height}@2x?access_token=${token}`
  return mapboxFetch(path)
    .then((blob) => {
      if (onSuccess) onSuccess(blob)
    })
    .catch((error) => {
      if (onFail) onFail(error)
    })
}
