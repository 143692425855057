import { defineStore } from "pinia"
import { startOfYear, endOfYear, getYear } from "date-fns"
import { fetchApiJson } from "@/services/repowerAPIService.js"

export default defineStore("invoicesStore", {
  state: () => ({
    portfolioId: null,
    dateRange: [startOfYear(new Date()), endOfYear(new Date())],
    invoices: [],
    loading: false,
    loadingFailed: false,
  }),
  actions: {
    loadInvoices(onSuccess, onFail) {
      this.loading = true
      this.loadingFailed = false
      fetchApiJson(
        `/v2/portfolios/${this.portfolioId}/invoices/?year=${getYear(this.dateRange[0])}`,
      )
        .then((data) => {
          this.invoices = data
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          this.invoices = []
          this.loadingFailed = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getInvoicePdf(invoice_number, onSuccess, onFail) {
      fetchApiJson(
        `/v2/portfolios/${this.portfolioId}/invoices/${invoice_number}/pdf/`,
      )
        .then((data) => {
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
  },
})
