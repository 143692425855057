import { defineStore } from "pinia"
import { fetchApi, fetchApiJson } from "@/services/repowerAPIService.js"
import useUserStore from "@/stores/userStore"
import * as Sentry from "@sentry/browser"

export default defineStore("sessionStore", {
  state: () => ({
    unknown: true,
    loading: false,
    loadingFailed: false,
    isAuthenticated: false,
    csrfToken: null,
  }),
  actions: {
    setAuthenticated(isAuthenticated) {
      this.isAuthenticated = Boolean(isAuthenticated)
      const userStore = useUserStore()
      if (this.isAuthenticated) {
        Sentry.setUser({ email: userStore.email })
      } else {
        Sentry.setUser(null)
      }
    },
    getSession(onAuthenticated, onUnauthenticated, onFail) {
      this.loading = true
      this.loadingFailed = false
      fetchApiJson("/users/session/", {
        credentials: "same-origin",
      })
        .then((data) => {
          this.setAuthenticated(data.isAuthenticated)
          if (data.isAuthenticated && onAuthenticated) {
            onAuthenticated(data)
          } else if (onUnauthenticated) {
            onUnauthenticated()
          }
        })
        .catch((error) => {
          this.setAuthenticated(false)
          if (error.code !== 403) {
            console.error(
              `Unable to fetch session, authentication revoked (code: ${error.code})`,
            )
            this.loadingFailed = true
            if (onFail) onFail(error)
          } else {
            if (onUnauthenticated) onUnauthenticated()
          }
        })
        .finally(() => {
          this.loading = false
          this.unknown = false
        })
    },
    requestCsrfToken() {
      fetchApi("/users/csrf/", {
        credentials: "same-origin",
      })
        .then((res) => {
          this.csrfToken = res.headers.get("X-CSRFToken")
        })
        .catch((error) => {
          console.error(`Unable to fetch CSRF token (code: ${error.code})`)
        })
    },
  },
})
