const typeMap = {
  pdf: "application/pdf",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export default function (data, type, filename) {
  if (!typeMap[type]) {
    console.error(`Unknown file type: ${type}`)
    return
  }
  const download = document.createElement("a")
  download.href = `data:${type};base64,${data}`
  download.download = filename
  download.click()
}
