<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader>Assets</TextHeader>
    <TabsComponent :tabs />
    <ToolbarComponent
      :assets-filter="assetsFilter"
      no-portfolios
      no-mode
      :no-datepicker="$route.name === 'assets-information'"
    >
      <ExportComponent
        v-if="$route.name === 'assets-analysis'"
        :metering-point="toolbarStore.selection"
        :date-range="toolbarStore.dateRange"
        type="measurements"
      />
    </ToolbarComponent>
    <RouterView />
  </div>
</template>

<script setup>
import { computed } from "vue"
import { startOfToday } from "date-fns"
import { useRoute } from "vue-router"
import { TextHeader } from "@repowerednl/ui-component-library"
import TabsComponent from "@/components/navigation/TabsComponent.vue"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import useToolbarStore from "@/stores/toolbarStore.js"

const toolbarStore = useToolbarStore()
const route = useRoute()

const tabs = computed(() => {
  const baseTabs = [
    {
      name: "Info",
      icon: "information",
      route: "assets-information",
    },
    {
      name: "Analyse",
      icon: "history",
      route: "assets-analysis",
    },
  ]
  if (!toolbarStore.selection || toolbarStore.selection?.type === "Battery") {
    baseTabs.push({
      name: "Verwachte omzet",
      icon: "money-euro-circle",
      route: "assets-expected-revenue",
    })
  }
  return baseTabs
})

if (!toolbarStore.selection?.isAsset) {
  toolbarStore.selection = null
  toolbarStore.dateRange = [startOfToday(), startOfToday()]
}

const assetsFilter = computed(() => {
  return route.name === "assets-expected-revenue"
    ? (asset) => asset.type === "Battery"
    : null
})
</script>
