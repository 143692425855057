import { defineStore } from "pinia"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

export default function (portfolioId) {
  const key = `singlePortfolioStore-${portfolioId}`
  return defineStore(key, {
    state: () => ({
      properties: {},
      recordStreamCollections: makeDataFetchObject(),
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
    },
    actions: {
      setPortfolio(portfolio) {
        this.properties = portfolio
      },
      loadRecordStreamCollection(
        freq,
        dateStart,
        dateEnd,
        getExport,
        onlyTotals,
        onSuccess,
        onFail,
      ) {
        const params = prepareDataFetch(
          getExport ? null : this.recordStreamCollections,
          freq,
          dateStart,
          dateEnd,
          false,
          onlyTotals,
          onSuccess,
        )
        if (!params) return
        const promise = fetchApiJson(
          `/v2/portfolios/${this.id}/record-stream-collections/`,
        ).then((collections) => {
          if (collections.length == 0) throw new Error("No collections present")
          return fetchApiJson(
            `/v2/record-streams/${collections[0].collection_id}/` +
              `${getExport ? "export/" : ""}?${params}`,
          )
        })
        handleDataFetchPromise(
          getExport ? null : this.recordStreamCollections,
          promise,
          params,
          onSuccess,
          onFail,
        )
      },
    },
  })() // <-- Note the () here to call the function
}
