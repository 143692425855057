<template>
  <div
    class="mx-auto flex max-w-[480px] flex-col items-center gap-4 md:mt-6 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-4 md:p-6">
      <TextTitle>{{ isWelcome ? "Welkom" : "Wachtwoord vergeten" }}</TextTitle>
      <template v-if="emailSent">
        <MessageComponent>
          <p>
            Als het e-mailadres bekend is, ontvang je binnen enkele minuten een
            e-mail om je wachtwoord
            {{ isWelcome ? "in te stellen" : "te wijzigen" }}.
          </p>
        </MessageComponent>
        <p>Je wordt automatisch doorgestuurd naar het inlogscherm.</p>
      </template>
      <template v-else>
        <p>
          Vul hier het e-mailadres in waarmee je bent geregistreerd. Je ontvangt
          binnen enkele minuten een e-mail om je wachtwoord
          {{ isWelcome ? "in te stellen" : "te wijzigen" }}.
        </p>
        <form class="flex w-full flex-col gap-4" @submit.prevent>
          <InputField
            v-model="inputEmail"
            label="E-mailadres"
            :disabled="isLoading"
            :helper-text="
              showValidation && !validEmail
                ? 'Vul een geldig e-mailadres in'
                : null
            "
            :state-error="showValidation && !validEmail"
          />
          <BaseButton
            color="primary"
            :loading="isLoading"
            class="w-fit"
            @click="onSend"
          >
            Verstuur
          </BaseButton>
        </form>
      </template>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { postApiCatch } from "@/services/repowerAPIService.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  TextTitle,
  BaseCard,
  BaseButton,
  InputField,
} from "@repowerednl/ui-component-library"
import MessageComponent from "@/components/information/MessageComponent.vue"

const route = useRoute()
const router = useRouter()
const notificationStore = useNotificationStore()
const inputEmail = ref("")
const showValidation = ref(false)
const emailSent = ref(false)
const isLoading = ref(false)
let timer = null

const isWelcome = computed(() => {
  return route.path.includes("welcome")
})

const validEmail = computed(() => {
  // This pattern should match the one used in the 'validate_email()' function
  // in the 'repower-django' backend.
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/
  return inputEmail.value.match(emailPattern)
})

function onSendSuccess() {
  emailSent.value = true
  timer = setTimeout(() => {
    router.replace({ name: "login" })
  }, 4000)
}

/**
 * Show a notification when the email address is refused by the server. This
 * can happen if the email validation done in the frontend is not strict
 * enough.
 */
function onSendEmailRefused() {
  notificationStore.pushContentError({
    title: "E-mailadres geweigerd",
    message:
      "Het opgegeven e-mailadres werd geweigerd. Blijft dit probleem zich voordoen, neem dan contact op met Repowered.",
    action: () => open("mailto:support@repowered.nl"),
    actionLabel: "Neem contact op",
  })
}

function onSendFail(error) {
  notificationStore.pushError(
    "Er ging iets mis bij de aanvraag",
    `De e-mail kon niet worden verstuurd. Probeer het later opnieuw. (code: ${error.code})`,
  )
}

function onSend() {
  if (!validEmail.value) {
    showValidation.value = true
    return
  }
  isLoading.value = true
  postApiCatch("/users/request-password-reset/", {
    user_email: inputEmail.value,
  })
    .then(() => {
      onSendSuccess()
    })
    .catch((error) => {
      if (error.code === 400) {
        onSendEmailRefused()
      } else {
        onSendFail(error)
      }
    })
    .finally(() => {
      isLoading.value = false
    })
}

onUnmounted(() => {
  clearTimeout(timer)
})
</script>
