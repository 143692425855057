import { defineStore } from "pinia"
import { marked } from "marked"
import DOMPurify from "dompurify"

const BASE_URL = "https://api.github.com/repos/repowerednl/wiki/contents/nl"

/**
 * Parses a wiki.js page into an object with metadata and sanitized HTML content
 */
function parse(text) {
  const [meta, markdown] = text.split(/\n---\n/)
  // Parse meta to object
  const metadata = [...meta.matchAll(/(.*): (.*)/g)].reduce(
    (obj, [_, key, value]) => ({ ...obj, [key]: value }),
    {},
  )
  // Parse markdown to HTML and sanitize to protect against XSS attacks
  const parsed = DOMPurify.sanitize(marked.parse(markdown))
  return { ...metadata, content: parsed }
}

export default defineStore("wikiStore", {
  state: () => ({
    pages: {},
  }),
  actions: {
    /**
     * Load a page with the given path from the wiki repository.
     */
    loadPage(path, onSuccess, onFail) {
      // Return cached page if available
      if (this.pages[path]) {
        if (onSuccess) onSuccess(this.pages[path])
        return
      }
      // Fetch page from GitHub repository
      fetch(`${BASE_URL}${path}.md`, {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_GITHUB_WIKI_TOKEN}`,
          Accept: "application/vnd.github.raw+json",
          "X-Github-Api-Version": "2022-11-28",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            return response.text()
          } else {
            const error = new Error(response.statusText)
            error.code = response.status
            throw error
          }
        })
        .then((text) => {
          this.pages[path] = parse(text)
          if (onSuccess) onSuccess(this.pages[path])
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
  },
})
